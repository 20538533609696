.icon {
  position: relative;

  display: block;
  width: 30px;
  height: 30px;

  font-size: 0;
  line-height: 0;
}

.img {
  background-color: unset;
}
