.header {
  margin: 0 0 var(--space-xl);

  font-weight: 500;
  font-size: 70px;
  font-family: var(--font-hero);
  line-height: 1.3;
  white-space: normal;
}

@media (--desktop-m) {
  .header {
    margin: 0 0 var(--space-m);

    font-size: var(--size-xxb);
  }
}

@media (--desktop) {
  .header {
    margin: 0 0 var(--space-s);

    font-size: 45px;
  }
}

@media (--mobile-m) {
  .header {
    font-size: var(--size-xxl);
  }
}
