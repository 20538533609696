.grid {
  display: grid;
  grid-template-columns: 260px 260px 260px 210px 200px;
  justify-content: space-between;
  width: 100%;
  row-gap: var(--space-xb);
}

.wrapperMedium {
  margin-bottom: var(--space-m);
}

.wrapperBig {
  margin-bottom: 45px;
}

.footerSubscription {
  width: 100%;
  max-width: 240px;
}

.madeIn {
  margin: 0;
}

.footerLink {
  display: block;

  font-size: var(--size-s);
  line-height: 1.5;
}

.paymentSecurityContainer {
  display: flex;
  justify-content: flex-end;
}

.paymentSecurity {
  white-space: nowrap;
}

.subscriptionForm {
  width: 240px;
}

.linkWrapper {
  margin-top: var(--space-xs);

  font-size: var(--size-s);

  &:first-child {
    margin-top: 0;
  }
}

.popoverInfo {
  position: relative;

  margin-left: 8px;

  vertical-align: text-bottom;
}

.popover {
  width: max-content;
  max-width: 246px;

  color: var(--color-dark-gray);
  font-size: var(--size-s);
  text-align: center;
}

.socials {
  height: 42px;
}
