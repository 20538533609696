.wrapper {
  width: 100%;
  height: 560px;
}

.map {
  position: relative;

  width: 100%;
  height: 100%;
  overflow: hidden;

  border: 1px solid var(--color-medium-gray);
  border-radius: var(--space-xxs);
}

.controls {
  position: absolute;
  top: var(--space-s);
  right: var(--space-s);
  z-index: 1;
}

.error {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: var(--space-xs) var(--space-s);

  font-weight: 500;

  font-size: var(--size-m);
  line-height: 1.5;
  column-gap: var(--space-xxs);

  background-color: var(--color-light-pink);
}

.wrapper [class*='copyrights-pane'] {
  display: none !important;
}

@media (--desktop-m) {
  .wrapper {
    height: 484px;
  }

  .error {
    font-size: var(--size-n);
  }
}

@media (--mobile-m) {
  .wrapper {
    height: 410px;
  }

  .error {
    font-size: var(--size-s);
  }
}

@media (--mobile) {
  .map {
    border: 0;
    border-radius: 0;
  }
}
