.title {
  padding-bottom: var(--space-l);

  font-size: var(--size-xl);
  line-height: 1.5;

  text-align: center;

  word-wrap: break-word;

  word-break: break-word;

  border-bottom: 1px solid var(--color-medium-gray);
}

@media (--mobile-m) {
  .title {
    font-size: var(--size-l);
    line-height: 1.2;
  }
}
