.placemark {
  position: relative;

  width: 50px;
  height: 50px;

  border-radius: 50%;
  box-shadow: 0 0 var(--space-n) 0 #00000033;
  transform: translate(-50%, -50%);
}

@media (--mobile-m) {
  .placemark {
    width: 45px;
    height: 45px;
  }
}
