.header {
  composes: main from 'containers';

  position: relative;

  z-index: 1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--space-s);
  justify-content: space-between;
  justify-content: space-between;
  width: 100%;
  padding-top: var(--space-s);
  padding-bottom: var(--space-m);

  background: var(--color-white);
  border-bottom: 1px solid transparent;

  transition: border 0.4s, padding 0.2s;

  &.withShadow {
    border-color: var(--color-light-gray);
  }
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.logotype {
  height: 30px;
}

.logotypeRu {
  height: auto;
}

.iconSearch {
  color: var(--color-grafit);

  cursor: pointer;
}

.burger {
  margin-right: var(--space-s);
}

.phone {
  margin-left: var(--space-m);
}

.callbackArrow {
  width: 10px;
  height: 10px;
  margin-left: var(--space-xxs);
}
