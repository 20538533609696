.link {
  display: flex;
  align-items: center;

  color: inherit;
  line-height: 1.5;
  white-space: nowrap;

  &:hover {
    .menuName {
      font-weight: 500;
    }

    .arrow {
      transform: rotate(90deg) scale(1.3);
    }
  }
}

.menuName {
  align-items: center;

  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
}

.arrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: auto 0 auto var(--space-xxs);

  transform: rotate(90deg);

  transition-timing-function: step-start;
}
