.input {
  flex-grow: 1;

  &.hasValue {
    .inputActions {
      .closeIcon {
        color: var(--color-grafit);
      }
    }
  }

  &.active {
    &.overrider {
      & > div {
        border-color: var(--color-black) !important;
      }
    }

    .inputActions {
      padding-right: var(--space-xxs);
    }

    .opened {
      display: flex;
    }

    .closed {
      display: none;
    }
  }

  &.overrider {
    & > div {
      height: 42px;
      padding: 0 var(--space-n);

      border-radius: 29px;

      input {
        padding: 9px 0;
        overflow: hidden;

        text-overflow: ellipsis;
      }
    }
  }
}

.icon {
  position: relative;

  flex: 0 0 20px;
  width: 20px;
  height: 20px;
}

.closeIcon,
.searchIcon {
  position: absolute;
  top: 50%;
  left: 50%;

  color: var(--color-grafit);

  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;

  transition: opacity 0.1s ease-in-out;
}

.searchIcon {
  z-index: 2;

  display: block;
  width: 20px;
  height: 20px;
}

.closeIcon {
  z-index: 1;

  width: 10px;
  height: 10px;

  color: var(--color-white);
}

.opened {
  display: none;
  gap: var(--space-xs);
  align-items: center;
  height: 100%;
}

.closed {
  display: block;
}

.input {
  .inputActions {
    .searchButton {
      height: 100%;
      padding: var(--space-xs) var(--space-m);

      font-size: var(--size-s);

      &:disabled,
      &.disabled {
        color: var(--color-white);

        background: var(--color-grafit);
        border-color: var(--color-grafit);
      }
    }
  }
}

.inputActions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  display: flex;
  gap: var(--space-xs);
  align-items: center;
  padding: 3px;
  padding-right: var(--space-n);
  overflow: hidden;
}
