.wrapperBig {
  margin-bottom: 45px;
}

.wrapperMedium {
  margin-bottom: var(--space-m);

  font-size: var(--size-s);
  line-height: 1.5;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 220px);
  justify-content: space-between;
  width: 100%;

  column-gap: var(--space-l);
  row-gap: var(--space-xb);
}

@media (--mobile-m) {
  .grid {
    grid-template-columns: repeat(3, 205px);
  }
}
