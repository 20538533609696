.header {
  position: relative;
  z-index: calc(var(--z-index-fixed-panel) + 2);

  height: 171px;

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    height: auto;

    .topline {
      z-index: 1;
    }
  }
}

.topline {
  position: relative;
  z-index: 51;
}

.compensation {
  height: 79px;

  transition: height 0.4s;
}

@media print {
  .header {
    display: none;
  }
  .compensation {
    display: none;
  }
}

@media (--desktop) {
  .header {
    height: auto;

    &.animable {
      transition: transform 0.3s;
    }

    &.fixed {
      transform: translate3d(0, -100%, 0);
    }

    &.fixed.pinned {
      transform: translate3d(0, 0, 0);
    }
  }
}

@media (--mobile-m) {
  .header {
    &.fixed {
      opacity: 0;
    }

    &.fixed.pinned {
      opacity: 1;
    }
  }
}
