.col {
  box-sizing: border-box;
  width: 100%;
}

.footerSubscription {
  width: 100%;
}

.wrapperRegular {
  margin-bottom: var(--space-s);
}

.wrapperMedium {
  margin-bottom: var(--space-m);
}

.wrapperBig {
  margin-bottom: 45px;
}

.linkWrapper {
  margin-top: var(--space-xxs);

  font-size: var(--size-s);
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }
}

.popoverInfo {
  position: relative;

  margin-left: var(--space-xs);

  vertical-align: text-bottom;
}

.popover {
  width: max-content;
  max-width: 246px;

  color: var(--color-dark-gray);
  font-size: var(--size-s);
  text-align: center;
}
