.logo {
  background-color: transparent;
}

.logoImage {
  position: static;

  display: block;
  width: auto;
}

.sizeS {
  height: 38px;
}

.sizeM {
  height: 48px;
}

.sizeL {
  height: 72px;
}

@media (--desktop-m) {
  .sizeS {
    height: 28px;
  }

  .sizeM {
    height: 38px;
  }

  .sizeL {
    height: 60px;
  }
}

@media (--desktop) {
  .sizeS {
    height: 18px;
  }

  .sizeM {
    height: 28px;
  }

  .sizeL {
    height: 50px;
  }
}

@media (--mobile) {
  .sizeM {
    height: 25px;
  }

  .sizeL {
    height: 45px;
  }
}
