.popup {
  position: absolute;
  top: calc(100% + 7px);
  z-index: 2;

  display: flex;
  flex-direction: column;
  width: max-content;
  padding: var(--space-s) var(--space-n);

  background-color: var(--color-white);

  border-radius: 4px;
  box-shadow: var(--shadow-popup);

  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  &.positionRight {
    right: var(--space-n-neg);
  }

  &.positionLeft {
    left: var(--space-n-neg);
  }

  &.fixed {
    transition: opacity 0s;
  }
}

.link {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
  justify-content: space-between;
  height: 35px;

  color: var(--color-black);
  font-size: var(--size-s);

  .linkText {
    background-repeat: no-repeat;
    background-position: left 0 bottom calc(16% - 3px);
    background-size: 100% 1px;
  }

  .linkIcon {
    width: 32px;
    height: 24px;

    background-color: transparent;
  }

  &:hover {
    .linkText {
      background-image: linear-gradient(currentColor, currentColor);
    }
  }
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 0;
}

.enterDone {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
}

.exitDone {
  opacity: 0;
}
