.phoneWrapper {
  display: inline-flex;
  align-items: center;

  cursor: pointer;

  transition: color 0.2s;

  &.viewSecondary {
    color: var(--color-dark-gray);
  }

  &.viewPrimary {
    color: var(--color-black);
  }

  &.sizeS {
    .phone {
      font-size: var(--size-s);
    }
  }

  &.sizeM {
    .phone {
      font-size: var(--size-n);
    }
  }

  &:hover {
    color: var(--color-black);
  }

  &:focus {
    color: var(--color-dark-gray);
  }
}

.phone {
  color: inherit;
  font-size: var(--size-s);
  line-height: 1.5;
  text-decoration: none;
}
