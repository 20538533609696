.wrapper {
  position: relative;

  width: max-content;
  height: auto;

  transform: translateX(-50%) translateY(-100%);
}

.layout {
  position: relative;

  width: max-content;
  height: auto;
  padding: 5px 10px 5px 5px;

  background-color: #fff;
  border-radius: var(--space-s);
  box-shadow: 0 0 var(--space-n) 0 #00000033;
  transform: translateY(-30%);
}

.layout::before {
  position: absolute;
  bottom: var(--space-xxs-neg);
  left: 50%;
  z-index: -1;

  width: 10px;
  height: 10px;

  background-color: #fff;
  transform: rotate(45deg) translateX(-50%);
  transform-origin: left;

  content: '';
}

.point {
  position: absolute;
  bottom: 0;
  left: 50%;

  width: 15px;
  height: 15px;

  border-radius: 50%;
  box-shadow: 0 0 var(--space-n) 0 #00000033;

  transform: translate(-50%, 50%);
}

.icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.content {
  display: flex;
  gap: var(--space-xs);
  align-items: center;

  font-size: var(--size-s);
}

@media (--mobile-m) {
  .icon {
    width: 40px;
    height: 40px;
  }
}
