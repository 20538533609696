.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inline {
  display: inline !important;
}

.wrapperText {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.timerWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.timerWrapper,
.textOffset {
  margin-left: var(--space-xxs);

  &:first-child {
    margin-left: 0;
  }
}

.timerText {
  display: inline;
  padding-right: var(--space-xxs);

  white-space: nowrap;
}

.wrapperArrow {
  display: flex;
  align-self: center;
}

.rArrow {
  display: inline-block;
  width: 8px;
  height: 13px;
  margin-left: var(--space-xs);

  vertical-align: middle;
}

.textStyle {
  font-size: 13px;
  line-height: 150%;
  white-space: nowrap;
}

.hideText {
  overflow: hidden;

  text-overflow: ellipsis;
}

@media (--mobile-m) {
  .wrapper {
    flex-direction: column;
  }

  .timerText,
  .textStyle {
    white-space: normal;
  }
}

@media (--mobile-s) {
  .wrapperText {
    display: inline-block;
  }
  .timerWrapper {
    margin-left: 0;
  }

  .rArrow {
    margin-left: var(--space-xxs);
  }
}

@media (--mobile-xxs) {
  .rArrow {
    display: none;
  }
}
