.footerLogo {
  display: block;
  width: 185px;
  height: 42px;
}

.imageWrapper {
  width: 100%;
  height: 100%;
}

.image {
  width: auto;
  object-fit: contain;
}
