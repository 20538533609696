.container {
  display: flex;
  gap: var(--space-n);
  box-sizing: border-box;
  width: 100%;
  padding: var(--space-n) var(--space-l);

  border: 1px solid var(--color-medium-gray);
  border-radius: var(--space-xxs);
}

.image {
  flex-shrink: 0;
  width: 30px;
  height: 30px;

  background-image: url('./images/phone.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);

  line-height: 1.5;
}

.title {
  max-width: 300px;

  font-weight: 500;
  font-size: var(--size-m);
}

.value {
  color: var(--color-grafit);
  font-size: var(--size-n);
  white-space: nowrap;
}

.button {
  display: none;
  -webkit-tap-highlight-color: transparent;
}

@media (--desktop-m) {
  .container {
    padding: var(--space-n);
  }
}

@media (--desktop) {
  .container {
    flex-direction: column;
  }

  .text {
    max-width: 250px;
  }
}

@media (--mobile-m) {
  .container {
    gap: var(--space-xs);
  }

  .text {
    gap: var(--space-xxs);
  }

  .title,
  .value {
    font-size: var(--size-s);
  }
}

@media (--mobile) {
  .container {
    gap: var(--space-n);
    align-items: center;
    padding: var(--space-l) var(--space-s);

    border: 0;
  }

  .text {
    max-width: 420px;

    text-align: center;
  }

  .title {
    max-width: 100%;

    font-size: var(--size-n);
  }

  .image,
  .value {
    display: none;
  }

  .button {
    display: block;
    width: 100%;
    max-width: 420px;
  }
}

@media (max-width: 520px) {
  .text,
  .title,
  .button {
    max-width: 100%;
  }
}
