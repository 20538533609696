.logotype {
  --logo-height: calc(396px / 11);
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
}

.main {
  display: block;
  flex-shrink: 0;
  box-sizing: border-box;

  background-color: unset;
}

.mainImg {
  object-fit: contain;
}

.mainKaz {
  width: 166px;
  height: 32px;
}

.mainRus {
  width: 147px;
  height: 32px;
}

.mainUzb {
  width: 152px;
  height: 32px;
}

.mainBlr {
  width: 153px;
  height: 40px;
}

@media (--mobile-m) {
  .mainKaz {
    width: 142px;
    height: 27px;
  }
  .mainRus {
    width: 125px;
    height: 27px;
  }
  .mainUzb {
    width: 130px;
    height: 27px;
  }
  .mainBlr {
    width: 120px;
    height: 30px;
  }
}

@media (--mobile-xxs) {
  .logotype {
    margin-bottom: 3px;
  }
}
