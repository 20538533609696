.grid {
  display: grid;
  grid-template-columns: repeat(5, 250px);
  justify-content: space-between;
  width: 100%;
  row-gap: var(--space-xb);
}

.wrapperMedium {
  margin-bottom: var(--space-m);
}

.wrapperBig {
  margin-bottom: 45px;
}
