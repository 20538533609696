.content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;

  transform: translate3d(0, -100%, 0);

  transition: transform 0.4s;
}

.backdrop {
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background: rgba(0, 0, 0, 0.3);

  cursor: pointer;
  opacity: 0;

  transition: opacity 0.4s;
}

.dropdown {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;

  &.opened {
    .backdrop {
      cursor: url('./cross.svg'), pointer;
    }
  }
}

.wrapper {
  background: white;
}

.enterActive,
.enterDone {
  &.dropdown {
    .content {
      transform: translate3d(0, 0, 0);
    }

    .backdrop {
      opacity: 1;
    }
  }
}

.exitActive,
.exitDone {
  &.dropdown {
    .content {
      transform: translate3d(0, -100%, 0);
    }
  }
}
