.nav {
  z-index: 3;

  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  max-width: 100%;
  margin: 0;
  padding: 0;

  column-gap: var(--space-m);

  background: transparent;
}

@media (--desktop-m) {
  .nav {
    height: 21px;
  }
}
