.footerNav {
  display: block;
  margin: 0;
  padding: 0;

  font-size: var(--size-s);
  line-height: 1.5;

  list-style: none;
}

.footerItem {
  margin-top: var(--space-s);
}
