.form {
  &.focused {
    .icon {
      color: var(--color-black);
    }
  }
}

.wrapper {
  position: relative;
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;

  width: 25px;

  height: 100%;
  padding: 0;

  font-size: 0;
  line-height: 0;

  background: transparent;
  border: none;
  cursor: pointer;
}

.icon {
  display: block;
  width: 10px;
  height: 15px;

  color: var(--color-dark-gray);

  transition: color 0.2s;
}
