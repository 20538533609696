.footer {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xb);

  background: var(--color-light-gray);
}

.content {
  composes: main from 'containers';
}

.copyright {
  color: var(--color-dark-gray);
  font-size: var(--size-s);
  line-height: 1.5;
}

.container {
  composes: main from 'containers';

  display: grid;
  grid-template-columns: repeat(5, 250px);
  justify-content: space-between;
  width: 100%;

  margin-top: var(--space-xb);
}

.logo {
  grid-column: 1 / span 1;
}

.socials {
  display: flex;
  grid-column: 2 / span 1;
}

.copyright {
  grid-column: 3 / -1;
}

.wideСopyright {
  grid-column: 2 / -1;
}

@media print {
  .footer {
    display: none;
  }
}

@media (--desktop) {
  .container {
    grid-template-columns: repeat(3, 220px);
    margin-top: var(--space-xxl);
  }

  .socials {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
    margin-top: 45px;
  }

  .copyright {
    grid-row: 1 / span 2;
    grid-column: 2 / -1;
  }
}

@media (--mobile-m) {
  .container {
    margin-top: var(--space-xb);
  }

  .socials {
    margin-top: var(--space-b);
  }
}

@media (--mobile) {
  .container {
    grid-template-columns: repeat(2, 250px);
  }

  .socials {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
    margin-top: 0;
  }

  .copyright {
    grid-row: 2 / span 2;
    grid-column: 1 / -1;
    margin-top: var(--space-m);
  }
}

@media (--mobile-xs) {
  .container {
    grid-template-columns: 100%;
    margin-top: 45px;
    row-gap: var(--space-m);
  }

  .socials {
    grid-row: 2;
    grid-column: 1;
  }

  .copyright {
    grid-row: 3;
    grid-column: 1;
    margin-top: 0;
  }

  .wideСopyright {
    grid-row: 2;
  }
}
