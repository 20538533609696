.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: auto;
  padding-inline: var(--space-s);
}

.map {
  margin-top: var(--space-l);
}

.mapPlaceholder {
  margin-top: var(--space-n);
}

.footer {
  margin-top: var(--space-b);
}

@media (--mobile) {
  .page.withBottomSheet {
    height: 100vh;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-inline: 0;
  }

  .cancellation {
    padding-inline: var(--space-s);
  }

  .map {
    flex-grow: 1;
    height: auto;
    margin-top: 0;
  }

  .mapPlaceholder {
    margin-top: 0;
  }

  .mapPlaceholder + .info {
    margin-top: var(--space-l);
  }

  .info + .footer {
    margin-top: 0;
  }
}
