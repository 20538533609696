.productList {
  display: flex;

  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 var(--space-xxb);
}

.lastTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--space-l);

  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;
}

.emptyResponse {
  margin: var(--space-l) auto 0;
}

.container {
  padding-top: var(--space-n);
}

@media (--mobile-m) {
  .productList {
    margin: var(--space-xxl) 0;
  }

  .emptyResponse {
    margin-bottom: var(--space-l);
  }
}
