.container {
  display: grid;
  grid-template-areas:
    'image title'
    'image description'
    '. value';
  grid-template-columns: 30px 1fr;
  align-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: var(--space-n) var(--space-l);

  line-height: 1.5;
  column-gap: var(--space-n);

  border: 1px solid var(--color-medium-gray);
  border-radius: var(--space-xxs);
  row-gap: var(--space-xs);
}

.image {
  grid-area: image;
  width: 30px;
  height: 30px;

  background-image: url('./images/delivery-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.title {
  grid-area: title;

  font-weight: 500;
  font-size: var(--size-m);
}

.description {
  grid-area: description;

  font-size: var(--size-n);
}

.value {
  grid-area: value;

  color: var(--color-grafit);
  font-size: var(--size-n);
}

@media (--desktop-m) {
  .container {
    padding: var(--space-n);
  }
}

@media (--desktop) {
  .container {
    grid-template-areas: 'image' 'title' 'description' 'value';
    grid-template-columns: 1fr;
    gap: 0;
  }

  .image {
    margin-bottom: var(--space-n);
  }

  .title,
  .description {
    margin-bottom: var(--space-xs);
  }
}

@media (--mobile-m) {
  .image {
    margin-bottom: var(--space-xs);
  }

  .title,
  .description,
  .value {
    font-size: var(--size-s);
  }

  .title,
  .description {
    margin-bottom: var(--space-xxs);
  }
}

@media (--mobile) {
  .container {
    grid-template-areas:
      'title'
      'description'
      'image'
      'value';
    grid-template-columns: 1fr;
    gap: 0;
    justify-items: center;
    padding: 0 var(--space-s);

    text-align: center;

    background-color: var(--color-white);
    border: 0;
  }

  .title {
    margin-bottom: var(--space-xxs);

    font-size: var(--size-xm);
  }

  .description {
    margin-bottom: var(--space-n);

    font-size: var(--size-n);
  }

  .image {
    width: 200px;
    height: 140px;
    margin-bottom: var(--space-n);

    background-image: url('./images/delivery-image.svg');
  }
}
