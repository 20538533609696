.header {
  position: relative;
  z-index: 10;

  display: block;
  width: 100%;

  background: var(--color-light-gray);

  transition: height 0.4s, margin 0.4s;
}

.container {
  composes: main from 'containers';
}

.wrapperLogotype {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.location {
  margin-right: var(--space-m);

  font-size: var(--size-n);
  line-height: 1.5;
}

.disabled {
  cursor: default;
}

.headerIn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-s) 0 var(--space-n);
}

.side {
  position: relative;

  display: flex;
  flex: 1;
  align-items: center;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.regionContainer {
  position: relative;

  margin-left: auto;
}

@media (--mobile-m) {
  .location {
    font-size: var(--size-s);
  }
}
