.searchDropDown {
  z-index: 2;

  padding-bottom: var(--space-l);

  background-color: var(--color-white);
  box-shadow: 0 var(--space-n) var(--space-n) var(--space-n-neg) rgba(0, 0, 0, 0.2);
}

.container {
  composes: main from 'containers';

  display: flex;
}

.searchContent {
  box-sizing: border-box;
  width: 100%;

  padding-left: var(--space-xxb);
}

.searchHistory {
  box-sizing: border-box;
  min-width: 302px;
  padding: var(--space-n) var(--space-xxl) var(--space-n) 0;

  border-right: 1px solid var(--color-medium-gray);
}

@media (--desktop-m) {
  .searchHistory {
    min-width: 263px;
    padding: var(--space-n) var(--space-n) var(--space-n) 0;
  }

  .searchContent {
    padding-left: var(--space-xxl);
  }
}
