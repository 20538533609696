.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.map {
  width: 100%;
  height: 340px;

  background-image: url('./images/desktop-map.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.text {
  font-weight: 500;
  font-size: var(--size-xl);
  font-family: var(--hero);
  line-height: 1.5;
  text-align: center;
  padding-block: var(--space-l);
}

.text::first-letter {
  text-transform: uppercase;
}

@media (--desktop-m) {
  .text {
    font-size: var(--size-l);
  }
}

@media (--mobile-m) {
  .text {
    padding-block: var(--space-n);

    font-size: var(--size-xm);
  }
}

@media (--mobile) {
  .wrapper {
    position: relative;

    flex-direction: column-reverse;
  }

  .wrapper::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 0.5px;

    background-image: url('./images/dash.svg');

    content: '';
  }

  .map {
    height: 176px;

    background-image: url('./images/mobile-map.png');
  }

  .text {
    padding-block: var(--space-n) var(--space-l);
  }
}
