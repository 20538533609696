.container {
  position: relative;
}

.langSwitch {
  display: flex;
  align-items: center;

  .title,
  .titleText {
    color: inherit;
  }

  &:hover {
    cursor: pointer;

    .title,
    .titleText {
      font-weight: 500;
    }

    .iconArrow {
      transform: rotate(90deg) scale(1.3);
    }
  }

  &.opened {
    .title {
      visibility: hidden;
    }

    .titleText {
      visibility: visible;
    }
  }
}

.title {
  position: absolute;
  top: 0;
  right: 0;

  padding-right: var(--space-s);

  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;
}

.titleText {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;

  visibility: hidden;
}

.iconArrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: auto 0 auto var(--space-xxs);

  transform: rotate(90deg);

  transition-timing-function: step-start;
}

.popup {
  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  &.fixed {
    transition: opacity 0s;
  }
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 0;
}

.enterDone {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
}

.exitDone {
  opacity: 0;
}
