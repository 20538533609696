.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1620px;
  padding-bottom: 80px;

  text-align: center;
  margin-inline: auto;
  padding-inline: 70px;
}

.image {
  width: 100%;
  height: 340px;
  margin-bottom: 40px;

  background-image: url('./images/desktop-map.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.info {
  max-width: 550px;
  margin-bottom: 30px;

  font-weight: 500;
  font-size: 22px;
}

.callCenter {
  font-size: 18px;
}

.footer {
  margin-top: auto;
}

@media (--desktop-m) {
  .content {
    padding-inline: 40px;

    padding-bottom: 60px;
  }

  .info {
    max-width: 450px;

    font-size: 18px;
  }

  .callCenter {
    font-size: 16px;
  }
}

@media (--mobile-m) {
  .content {
    padding-inline: 15px;

    padding-bottom: 40px;
  }

  .image {
    margin-bottom: 30px;
  }

  .info {
    max-width: 400px;
    margin-bottom: 20px;

    font-size: 16px;
  }

  .callCenter {
    font-size: 14px;
  }
}

@media (--mobile) {
  .content {
    padding-inline: 0;
  }

  .image {
    height: 176px;

    background-image: url('./images/mobile-map.png');
  }

  .info,
  .callCenter {
    padding-inline: 15px;
  }
}
