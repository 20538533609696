.defaultWrapper {
  & .timer {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: minmax(62px, auto) 20px minmax(62px, auto) 20px minmax(62px, auto);
    align-items: end;

    font-weight: 500;
    font-size: var(--size-xb);
    font-family: var(--font-hero);
    line-height: 1.3;
    text-align: center;
  }

  & .textBeforeTimer {
    font-weight: 500;
    font-size: var(--size-l);
    line-height: 1.3;
  }

  & .textAfterTimer {
    padding-left: var(--space-xs);

    font-weight: 500;
    font-size: var(--size-l);
    line-height: 2;
  }
}

.inlineWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0 5px;
  padding: 10px 15px;

  font-size: 13px;

  & .timer {
    display: flex;
    gap: 5px;

    font-weight: 500;
    white-space: nowrap;
  }
}

@media (--desktop-m) {
  .defaultWrapper {
    & .timer {
      grid-template-columns: minmax(48px, auto) 15px minmax(48px, auto) 15px minmax(48px, auto);

      font-size: var(--size-b);
    }

    & .textBeforeTimer {
      font-size: var(--size-m);
    }

    & .textAfterTimer {
      font-size: var(--size-m);
    }
  }
}

@media (--desktop) {
  .defaultWrapper {
    & .textAfterTimer {
      font-size: var(--size-n);
    }
  }
}

@media (--mobile-m) {
  .defaultWrapper {
    & .timer {
      grid-template-columns: minmax(42px, auto) 15px minmax(42px, auto) 15px minmax(42px, auto);

      font-size: var(--size-xxl);
    }

    & .textBeforeTimer {
      font-size: var(--size-n);
    }
  }
}

@media (--mobile) {
  .defaultWrapper {
    & .textBeforeTimer {
      font-size: var(--size-xs);
    }
  }
}

.defaultWrapper .timer :is(:nth-child(2), :nth-child(4)) {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
