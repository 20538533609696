.button {
  position: relative;

  width: 42px;
  height: 42px;
  overflow: hidden;

  background-color: var(--color-white);
  border: 1px solid var(--color-medium-gray);
  border-radius: 50%;
  box-shadow: 0 0 var(--space-n) 0 #00000033;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.button.increment::before,
.button.decrement::before {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 15px;
  height: 1px;

  background-color: var(--color-grafit);
  transform: translate(-50%, -50%);

  content: '';
}

.button.increment::after {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 1px;
  height: 15px;

  background-color: var(--color-grafit);
  transform: translate(-50%, -50%);

  content: '';
}
