.container {
  display: flex;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: var(--space-l);
}

.sidebar {
  flex-basis: 372px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: var(--space-n);

  border-right: 1px solid var(--color-medium-gray);

  .menuItem {
    &.promo {
      color: var(--color-red);
    }
  }
}

.content {
  composes: main from 'containers';

  padding-top: var(--space-n);
  padding-bottom: var(--space-n);
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 1.1fr;
  gap: var(--space-l);
}

.banner {
  margin-bottom: var(--space-n);

  background-color: var(--color-light-gray);
}

.menuItemContainer {
  display: flex;
  gap: var(--space-s);
  align-items: center;
  height: 50px;
  padding: 0 var(--space-xxl) 0 var(--space-xxb);

  font-size: var(--size-n);
  line-height: 1.5;

  background-color: var(--color-white);

  transition: background-color 0.2s ease-in-out;
}

.menuItem {
  color: var(--color-grafit);
  white-space: nowrap;

  &:hover,
  &.active {
    .menuItemContainer {
      background-color: var(--color-light-gray);
    }

    .iconChevronRight {
      opacity: 1;
    }
  }
}

.iconChevronRight {
  width: 15px;
  height: 15px;
  margin-left: auto;

  color: var(--color-grafit);

  opacity: 0;

  transition: opacity 0.2s ease-in-out;
}

.title {
  display: inline-block;
  margin-top: var(--space-xxs);

  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-l);
  font-family: var(--font-hero);
  line-height: 1.5;
}

.link {
  color: var(--color-black);
  font-size: var(--size-s);
  line-height: 1.5;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--color-dark-gray);
  }
}

.list {
  display: inline-block;
  width: 100%;
  margin-top: var(--space-b);

  vertical-align: top;

  .subtitle {
    color: var(--color-black);
    font-weight: 500;
    font-size: var(--size-n);
    line-height: 1.5;
    vertical-align: baseline;

    cursor: default;

    &.pointer {
      cursor: pointer;
    }
  }
}

.sublist {
  margin-top: var(--space-n);
}

.sublist {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}

.columns {
  display: flex;
  gap: var(--space-l);

  &.mini {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.column {
  width: 50%;
}

.icon {
  width: 32px;
  height: 24px;

  background-color: transparent;
}

.iconImage {
  object-fit: contain;
}

.commonLinks {
  margin-top: var(--space-b);
}

.commonLink {
  position: relative;

  display: inline-block;

  color: var(--color-black);
  font-size: var(--size-n);
  line-height: 1.5;
  vertical-align: baseline;

  cursor: pointer;
}

.bannerContainer {
  display: flex;
  gap: var(--space-n);
  box-sizing: border-box;
  width: 100%;
  padding: var(--space-n);

  color: var(--color-grafit);

  background: var(--color-light-gray);
  border-radius: 4px;
}

.bannerIcon {
  width: 100%;
  height: 100%;

  background-color: transparent;
}

.bannerName {
  margin-bottom: var(--space-xxs);

  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-n);
  line-height: 1.5;
}

.bannerLink {
  margin-top: var(--space-xxs);

  color: var(--color-black);
  font-size: var(--size-s);
}

.bannerDescription {
  font-size: var(--size-s);
  line-height: 1.5;
}

.banners {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--space-n);
}

.bannersContainer {
  display: grid;
  grid-template-areas:
    'a a a a'
    'b b c c';
  gap: var(--space-n);
  padding-bottom: var(--space-n);

  &.rows {
    grid-template-areas:
      'a a a a'
      'b b b b';
  }
}

.saleBanner {
  display: flex;
  box-sizing: border-box;
  width: 100%;

  &:nth-child(3n + 1) {
    grid-area: a;
  }

  &:nth-child(3n + 2) {
    grid-area: b;
  }

  &:nth-child(3n + 3) {
    grid-area: c;
  }
}

.section {
  margin-top: var(--space-n);

  &:first-child {
    margin-top: 0;
  }
}

@media (--desktop-m) {
  .menuItemContainer {
    padding: 0 var(--space-n) 0 var(--space-xxl);
  }

  .sidebar {
    flex-basis: 303px;
  }

  .banners {
    grid-template-columns: 1fr;
  }
}
