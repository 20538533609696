.logotype {
  display: block;
  width: 210px;
  height: 42px;

  background-color: unset;
}

.logotypeImg {
  object-fit: contain;
}

.logoKaz {
  width: 218px;
}

@media (--desktop) {
  .logotype {
    width: 150px;
    height: 30px;
  }

  .logoKaz {
    width: 156px;
  }
}
