.back {
  display: flex;
  gap: var(--space-xs);
  align-items: center;

  color: var(--color-black);
  font-size: var(--size-s);
  line-height: 1.5;

  cursor: pointer;
}

.icon {
  width: 25px;
  height: 25px;

  color: var(--color-black);
}
