.mainWrapper {
  display: flex;
  justify-content: flex-start;
}

.menuItem {
  position: relative;

  margin-left: var(--space-m);

  &:first-child {
    margin-left: 0;
  }
}
