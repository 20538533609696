.input {
  width: 574px;

  height: 50px;
  margin-bottom: var(--space-xxl);

  &.hasValue {
    .inputActions {
      .closeIcon {
        color: var(--color-grafit);
      }
    }
  }

  &.overrider {
    & > div {
      height: 50px;
      padding: 0 var(--space-n);

      border-color: var(--color-black) !important;

      border-radius: 29px;

      input {
        padding: 11px 0;
        overflow: hidden;

        text-overflow: ellipsis;
      }
    }
  }

  .inputActions {
    display: flex;
    padding-right: var(--space-xxs);

    .searchButton {
      height: 100%;
      padding: var(--space-xs) var(--space-m);

      font-size: var(--size-s);

      &:disabled {
        color: var(--color-white);

        background: var(--color-grafit);
        border-color: var(--color-grafit);
      }
    }
  }
}

.icon {
  position: relative;

  flex: 0 0 20px;
  width: 20px;
  height: 20px;
}

.closeIcon {
  position: absolute;
  top: 50%;
  left: 50%;

  color: var(--color-grafit);

  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;

  transition: opacity 0.1s ease-in-out;
}

.closeIcon {
  z-index: 1;

  width: 10px;
  height: 10px;

  color: var(--color-white);
}

.inputActions {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  display: flex;
  gap: var(--space-xs);
  align-items: center;
  padding: 3px;
  padding-right: var(--space-xxs);

  overflow: hidden;

  .searchButton {
    height: 100%;
    padding: var(--space-xs) var(--space-m);

    font-size: var(--size-s);

    &:disabled {
      color: var(--color-white);

      background: var(--color-grafit);
      border-color: var(--color-grafit);
    }
  }
}

@media (--desktop-m) {
  .input {
    width: 628px;
  }
}

@media (--desktop) {
  .input {
    height: 42px;

    &.overrider {
      & > div {
        height: 42px;

        input {
          padding: 9px 0;
        }
      }
    }
  }
}

@media (--mobile-m) {
  .input {
    width: 486px;
  }
}

@media (--mobile) {
  .search {
    width: 100%;
  }

  .input {
    width: 100%;
  }
}
