.footerNavItem {
  position: relative;

  display: block;

  &.phone {
    margin-top: var(--space-xxs);
  }

  &:first-child {
    margin-top: 0;
  }

  &.hasIcon {
    display: flex;
    align-items: center;
  }
}

.footerNavItemTitle {
  display: flex;
}

.icon {
  margin-right: var(--space-xs);
}

.footerNavLink {
  color: var(--color-black);

  text-decoration: none;

  cursor: default;

  transition: color 0.4s ease-in-out;

  &.interactive {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: var(--color-grafit);
      font-weight: 500;
    }
  }
}

.rating {
  margin-left: var(--space-xs);
}

.dropdownLinks {
  position: absolute;
  top: 0;
  left: -25%;
}

@media (--mobile) {
  .dropdownLinks {
    left: 0;
  }
}

@media (--mobile-s) {
  .rating {
    display: none;
  }

  .dropdownLinks {
    left: -15px;
  }
}
