.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow-x: auto;

  column-gap: var(--space-m);

  list-style: none;

  .menuButton.burgerButton {
    --main-border: 1.5px ridge var(--color-grafit);
    height: 42px;

    font-size: var(--size-m);
    line-height: 1.5;

    border: var(--main-border);

    border-radius: 50px;

    &:hover {
      border: var(--main-border);
    }
    &:active {
      color: var(--color-black);

      background-color: var(--color-medium-gray);
      border: var(--main-border);
    }
    &:focus-visible {
      outline: 0.5px solid var(--color-black);
    }
  }

  @media (--only-desktop) {
    overflow: inherit;
  }
}

.menuIcon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  margin-right: var(--space-xs);
  padding: 2px 0;

  &.opened {
    .menuIconItem {
      width: 20px;

      opacity: 0;

      &:first-child {
        transform: translateY(6px) rotate(45deg);
        opacity: 1;
      }

      &:last-child {
        transform: translateY(-6px) rotate(-45deg);
        opacity: 1;
      }
    }
  }
}

.menuIconItem {
  width: 18px;
  height: 2px;

  transition: 0.3s;
}

.linkWrapper {
  display: block;
}

.link {
  position: relative;

  display: block;

  margin: 0;

  color: var(--color-black);
  font-weight: 500;

  font-size: var(--size-m);
  line-height: 1.5;
  white-space: nowrap;
  text-decoration: none;

  transition: color 150ms ease-in;

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    height: 1px;

    background: var(--color-black);
    opacity: 0;

    transition: opacity 150ms ease-in;

    content: '';
  }
}
