.tips {
  width: 100%;
  overflow: hidden;
}

.tip {
  margin-right: var(--space-s);

  &:last-child {
    margin-right: 0;
  }
}

.name {
  padding: 7px var(--space-s);

  color: var(--color-black);

  font-weight: 400;
  font-size: var(--size-s);
  line-height: 1.5;

  white-space: nowrap;

  background-color: var(--color-white);
  border-radius: 45px;

  box-shadow: inset 0 0 0 1px var(--color-medium-gray);
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-light-gray);
  }
}

@media (--desktop) {
  .tip {
    margin-right: var(--size-xs);
  }
}
