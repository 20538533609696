.wrapper {
  width: 100%;
}

.banner {
  position: relative;

  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 66%;
}

.image {
  position: absolute;
  inset: 0;
}

.text {
  position: absolute;

  display: flex;
  gap: 20px;
  align-items: flex-end;

  color: var(--color-black);
  line-height: 1.5;
  inset: 30px 20px;

  .link {
    &:after {
      background-color: var(--color-black);
    }

    &:before {
      background-color: var(--color-black);
    }
  }

  &.inversed {
    color: var(--color-white);

    .link {
      &:after {
        background-color: var(--color-white);
      }

      &:before {
        background-color: var(--color-white);
      }
    }
  }
}

.title {
  font-weight: 500;
  font-size: 25px;
  font-family: var(--font-hero);
}

.subtitle {
  margin-top: var(--space-xs);

  font-size: var(--size-n);
}

.link {
  margin-left: auto;

  font-size: var(--size-n);
  line-height: 1.5;
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-l);
  }

  .subtitle {
    font-size: var(--size-s);
  }
}
