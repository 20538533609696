.mainWrapper {
  display: flex;
  justify-content: flex-end;
}

.location {
  display: flex;
  align-items: center;

  margin-right: var(--space-m);

  &:hover {
    cursor: pointer;

    .locationText {
      font-weight: 500;
    }

    .locationArrow {
      transform: rotate(90deg) scale(1.3);
    }
  }
}

.locationIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: var(--space-xxs);
}

.locationIcon {
  color: inherit;
}

.callback {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;

    .callbackArrow {
      transform: rotate(90deg) scale(1.3);
    }

    .callbackText {
      font-weight: 500;
    }
  }
}

.locationText,
.callbackText {
  align-items: center;

  color: inherit;

  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
}

.callbackArrow,
.locationArrow {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: auto 0 auto var(--space-xxs);

  transform: rotate(90deg);

  transition-timing-function: step-start;
}

.mainWrapper {
  position: relative;

  .callback {
    .phone {
      color: inherit;
    }
  }
}
