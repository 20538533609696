.accordion {
  display: block;

  &.disabled {
    .header {
      color: var(--color-medium-gray);

      cursor: default;

      &:after {
        background-image: url('arrow-disabled.svg');
      }
    }
  }

  &.collapsed {
    .header:after {
      transform: rotate(0deg);
    }
  }
}

.header {
  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-s);
  line-height: 1.5;

  cursor: default;
}

.content {
  padding-top: 15px;
}

@media (--mobile-s) {
  .accordion {
    &:after {
      display: block;
      margin-top: var(--space-s);

      border-top: 1px solid var(--color-medium-gray);
      transform: translateY(-1px);

      content: '';
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    &:after {
      display: block;
      width: 15px;
      height: 10px;

      background-image: url('arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;

      transform: rotate(-180deg);

      transition: transform 0.2s ease-in;

      content: '';
    }
  }
}
