.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: auto;
  padding-inline: 220px;
}

.map {
  margin-top: var(--space-l);
}

.mapPlaceholder {
  margin-top: var(--space-l);
}

.footer {
  margin-top: var(--space-e);
}

@media (--desktop-m) {
  .container {
    padding-inline: var(--space-xxl);
  }

  .mapPlaceholder {
    margin-top: var(--space-n);
  }

  .footer {
    margin-top: var(--space-xb);
  }
}
