.info {
  display: flex;
  gap: var(--space-xl);
  box-sizing: border-box;
  width: 100%;
}

@media (--desktop-m) {
  .info {
    gap: var(--space-n);
  }
}

@media (--mobile-m) {
  .info {
    gap: var(--space-s);
  }
}

@media (--mobile) {
  .info {
    flex-direction: column;
    gap: 0;
  }

  .delivery {
    position: relative;

    padding-bottom: var(--space-l);
  }

  .courier {
    position: relative;
  }

  .delivery:after,
  .courier::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 2px;

    background-image: url('./images/dash.svg');

    content: '';
  }
}
