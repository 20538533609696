.footer {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xb);

  background: var(--color-light-gray);
}

.content {
  composes: main from 'containers';
}

.copyright {
  margin-top: var(--space-xxl);
  padding-top: var(--space-xxl);

  border-top: 1px solid var(--color-medium-gray);
}

.container {
  composes: main from 'containers';
}

@media print {
  .footer {
    display: none;
  }
}

@media (--mobile-s) {
  .content {
    display: block;
  }
}
