.langItem {
  display: flex;
  gap: var(--space-xs);
  align-items: center;

  color: var(--color-dark-gray);
}

.selected {
  color: var(--color-black);
}

.image {
  display: block;
  width: 20px;
  height: 20px;
  padding-bottom: 0;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.title {
  font-size: var(--size-s);
  line-height: 1.5;
  text-wrap: nowrap;
}
