.sectionTitle {
  display: flex;
  gap: var(--space-m);
  align-items: center;
  margin-bottom: var(--space-l);

  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-l);
  font-family: var(--font-hero);
  line-height: 1.5;

  background-color: var(--color-light-gray);
  border-radius: 4px;
}

.sectionImage {
  flex: 0 0 50%;
  max-width: 335px;
  overflow: hidden;

  background-color: transparent;
  border-radius: 4px;
  aspect-ratio: '251:99';
}

.link {
  color: var(--color-black);
  font-size: var(--size-s);
  line-height: 1.5;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--color-dark-gray);
  }
}

.sectionSublist {
  flex: 1 1 100%;
  gap: var(--space-l);
  margin-top: var(--space-s-neg);

  column-count: 2;
  column-fill: balance;

  .link {
    display: block;
    padding-top: var(--space-s);
  }
}

.section {
  display: flex;
  flex-direction: column;
}

.title {
  display: inline-block;

  vertical-align: baseline;
}

@media (--desktop-m) {
  .sectionImage {
    flex: 0 0 60%;
    max-width: 251px;
  }

  .sectionTitle {
    gap: 0;
  }
}
