.wrapperBig {
  margin-bottom: 45px;
}

.footerSubscription {
  width: 100%;
  max-width: 240px;
}

.wrapperMedium {
  margin-bottom: var(--space-m);

  font-size: var(--size-s);
  line-height: 1.5;
}

.subscriptionForm {
  width: 100%;
  max-width: 240px;
}

.linkWrapper {
  margin-top: var(--space-xxs);

  font-size: var(--size-s);
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }
}

.popoverInfo {
  position: relative;

  margin-left: 8px;

  vertical-align: text-bottom;
}

.popover {
  width: max-content;
  max-width: 246px;

  color: var(--color-dark-gray);
  font-size: var(--size-s);
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: 290px 260px 210px;
  justify-content: space-between;
  width: 100%;

  column-gap: var(--space-l);
  row-gap: var(--space-xb);
}

.socials {
  height: 42px;
}

@media (--mobile-m) {
  .grid {
    grid-template-columns: 250px 220px 200px;
  }
}
