/* Контейнеры задают максимально возможную ширину блока и отступы.
Примешиваем стили нужного контейнера к своему блоку с помощью composes. */

/* основной контейнер с максимальной шириной контента 1780px */
.main {
  --gap: var(--space-xxb);
  box-sizing: border-box;
  width: 100%;
  max-width: calc(1780px + var(--gap) * 2);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--gap);
  padding-left: var(--gap);
}

/* контейнер с максимальной шириной контента 1176px */
.narrow {
  --gap: var(--space-xxl);
  --width: 1256px;
  box-sizing: border-box;
  width: 100%;
  max-width: var(--width);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--gap);
  padding-left: var(--gap);
}

/* Используется для текстовых описаний к секциям */
.averageText {
  box-sizing: border-box;
  width: 100%;
  max-width: 1328px;
}

/* Используется для узких текстовых описаний */
.smallText {
  box-sizing: border-box;
  width: 100%;
  max-width: 1176px;
}

/* добавляет белые градиенты по бокам блока на разрешениях больше 1900px */
.cropped {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;

    display: block;
    width: 70px;
  }

  &:before {
    left: 0;

    background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
  }

  &:after {
    right: 0;

    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
  }
}

@media (--desktop-l) {
  .cropped {
    overflow: hidden;

    &:before,
    &:after {
      content: '';
    }
  }
}

@media (--desktop-m) {
  .main {
    --gap: var(--space-xxl);
  }

  .narrow {
    --width: 1146px;
  }

  .averageText {
    max-width: 957px;
  }
}

@media (--mobile-m) {
  .main {
    --gap: var(--space-s);
  }

  .narrow {
    --gap: var(--space-s);
  }
}
