.logotypeWrapper {
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
}

.logo {
  width: 175px;
  height: 42px;

  background-image: url('logos/RU/logoRu.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.logoBy {
  width: 185px;
  height: 42px;

  background-image: url('logos/BY/logoBy.svg');
}

.logoKz {
  width: 182px;
  height: 42px;

  background-image: url('logos/KZ/logoKz.svg');
}

.logoUz {
  background-image: url('logos/UZ/logoUz.svg');
}

@media (--desktop) {
  .logo,
  .logoUz {
    width: 125px;
    height: 30px;
  }
  .logoBy {
    width: 132px;
    height: 30px;
  }
  .logoKz {
    width: 130px;
    height: 30px;
  }
}

@media (--mobile-xxs) {
  .logo,
  .logoBy {
    width: 30px;
    height: 30px;

    background-image: url('logos/RU/logoRuMini.svg');
  }
  .logoKz {
    background-image: url('logos/KZ/logoKzMini.svg');
  }
}
