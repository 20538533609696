.lists {
  .oftenSearch {
    margin-bottom: var(--space-xxl);
  }

  .categoryFound {
    margin: 0 0 var(--space-xxs);

    font-weight: 500;
    font-size: var(--size-n);
    line-height: 150%;
  }

  .found {
    margin-top: var(--space-xs);

    .requestTite {
      margin: 0;

      color: var(--color-black);
      font-weight: 400;
      font-size: var(--size-s);
      line-height: 150%;

      word-break: break-all;
    }

    .requestSubtitle {
      margin: 0;

      color: var(--color-dark-gray);
      font-weight: 400;
      font-size: var(--size-xs);
      line-height: 150%;
    }

    &:hover {
      .requestTite {
        font-weight: 500;

        cursor: pointer;
      }
    }
  }
}

.tips {
  margin-bottom: var(--space-l);
}

@media (--mobile-m) {
  .lists {
    &:last-child {
      margin-right: 0;
    }
  }
}

@media (--mobile) {
  .history {
    width: 100%;
    margin-right: 0;
  }
  .lists {
    margin-right: 0;
  }
}
