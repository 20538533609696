.container {
  position: relative;

  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  inset: 0;

  z-index: 1;
}

.text {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 40px;

  color: var(--color-black);
  line-height: 1.5;

  &.leftTop {
    align-items: flex-start;
  }

  &.leftCenter {
    align-items: flex-start;

    & :first-child {
      margin-top: auto;
    }
  }

  &.centerTop {
    align-items: center;

    text-align: center;
    margin-inline: auto;
  }

  &.centerCenter {
    align-items: center;

    text-align: center;
    margin-inline: auto;

    & :first-child {
      margin-top: auto;
    }
  }

  &.full {
    max-width: 573px;

    & .logo {
      margin-top: 0;
      margin-bottom: 20px;
    }

    & .logo ~ .period {
      margin-top: auto;
    }

    & .period {
      font-size: 18px;
    }

    & .title {
      font-size: 44px;
    }

    & .subtitle {
      font-size: 25px;
    }

    & .link {
      font-size: 22px;
    }
  }

  &.half {
    max-width: 423px;

    & .logo {
      margin-top: auto;
      margin-bottom: auto;

      img {
        width: 80%;
        object-fit: contain;
      }
    }

    & .period {
      font-size: 16px;
    }

    & .title {
      font-size: 35px;
    }

    & .subtitle {
      font-size: 22px;
    }

    & .link {
      font-size: 22px;
    }
  }

  .link {
    &:after {
      background-color: var(--color-black);
    }

    &:before {
      background-color: var(--color-black);
    }
  }

  &.inversed {
    color: var(--color-white);

    .link {
      &:after {
        background-color: var(--color-white);
      }

      &:before {
        background-color: var(--color-white);
      }
    }
  }
}

.period {
  margin-bottom: 20px;

  font-weight: 500;
}

.title {
  margin-bottom: 20px;

  font-weight: 500;
  font-family: var(--font-hero);
}

.subtitle {
  margin-bottom: 20px;
}

.link {
  position: relative;

  margin-bottom: auto;

  font-weight: 500;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 1px;

    opacity: 1;

    transition: opacity 100ms ease-in;

    content: '';
  }

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;

    display: block;
    width: 0;
    height: 1px;

    transition: width 250ms ease-in 100ms;

    content: '';
  }
}

.timer {
  margin-top: 20px;
  margin-bottom: 5px;
}

.timer:last-child {
  margin-bottom: 0;
}

@media (hover: hover) {
  .link:hover {
    &:before {
      width: 100%;
    }
    &:after {
      opacity: 0;
    }
  }
}

@media (--desktop-m) {
  .text {
    padding: 25px;

    &.full {
      max-width: 496px;

      & .period {
        font-size: 16px;
      }

      & .title {
        font-size: 30px;
      }

      & .subtitle {
        font-size: 18px;
      }

      & .link {
        font-size: 18px;
      }
    }

    &.half {
      max-width: 365px;

      & .period {
        font-size: 16px;
      }

      & .title {
        font-size: 25px;
      }

      & .subtitle {
        font-size: 16px;
      }

      & .link {
        font-size: 18px;
      }
    }
  }

  .period {
    margin-bottom: 15px;
  }

  .title {
    margin-bottom: 15px;
  }

  .subtitle {
    margin-bottom: 15px;
  }

  .timer {
    margin-top: 15px;
  }
}

@media (--desktop) {
  .container {
    height: auto;
  }

  .text {
    height: 393px;
    padding: 25px 15px;

    &.sameAlignment {
      align-items: flex-start;
      justify-content: flex-end;

      text-align: left;
    }

    &:is(.full, .half) {
      max-width: 100%;

      & .period {
        font-size: 12px;
      }

      & .title {
        font-size: 22px;
      }

      & .subtitle {
        font-size: 14px;
      }

      & .link {
        font-size: 16px;
      }
    }
  }

  .period {
    margin-bottom: 10px;
  }

  .title {
    margin-bottom: 10px;

    line-height: 1.3;
  }

  .subtitle {
    margin-bottom: 10px;
  }

  .link {
    margin-bottom: 0;
  }

  .timer {
    margin-top: 0;

    background-color: var(--color-light-gray);
  }
}

@media (--mobile) {
  .text {
    &.half {
      & .logo {
        margin-top: 0;

        img {
          width: 100%;
        }
      }
    }
  }
}
