.list {
  gap: var(--space-n);
  align-items: baseline;
}

.link {
  grid-gap: inherit;
  grid-template-columns: inherit;
}

.imgWrapper {
  height: 100%;
  overflow: hidden;

  aspect-ratio: '265.6:166';
}

.cnImage {
  object-fit: contain;
}

.name {
  height: 42px;
  margin-top: var(--space-s);
  margin-bottom: var(--space-xxs);
  overflow: hidden;

  color: var(--color-black);
  font-size: var(--size-s);
  line-height: 1.5;
  text-decoration: none;
}

.price {
  margin: 0;

  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;
}

.row {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(5, 1fr);

  align-items: flex-start;

  column-gap: var(--space-n);
  row-gap: var(--space-n);
}

@media (--desktop-m) {
  .row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--mobile-m) {
  .list {
    grid-column-gap: var(--space-n);
  }
}

@media (--mobile) {
  .list {
    grid-column-gap: var(--space-s);
  }
}
