.imageWrapper {
  position: relative;

  height: 100%;

  background-color: var(--color-light-gray);
}

.image {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: inherit;
  object-fit: cover;

  color: var(--color-light-gray);

  vertical-align: bottom;
}
