.wrapperDropdown {
  z-index: 1;

  padding-top: var(--space-m);
}

.dropdown {
  display: flex;
  flex-direction: column;
  max-width: 226px;

  margin: 0;
  padding: var(--space-s);

  background-color: var(--color-white);
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.dropdownItem {
  display: flex;
  justify-content: flex-start;

  margin-bottom: var(--space-xxs);

  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  align-self: flex-end;
  margin-bottom: var(--space-xxs);

  color: var(--color-grafit);

  &:hover {
    color: var(--color-dark-gray);
  }
}

.dropdownText {
  color: var(--color-dark-gray);
}

@media (--mobile-s) {
  .wrapperDropdown {
    width: 100%;
  }

  .dropdown {
    width: 100%;
    max-width: 100%;

    border-radius: 0;
  }

  .dropdownItem {
    justify-content: flex-start;
  }
}
