.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  overflow: hidden;

  background-color: var(--color-white);
  border: 1px solid var(--color-medium-gray);
  border-radius: 50%;
  box-shadow: 0 0 var(--space-n) 0 #00000033;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.icon {
  width: 15px;
  height: 15px;

  transform: translate(-1px, 1px);
}
