.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  line-height: 1.5;
  text-align: center;

  padding-inline: 15px;
}

.image {
  width: 200px;
  height: 140px;
  margin-bottom: var(--space-xxl);
}

.date {
  margin-bottom: var(--space-xs);

  font-weight: 500;
  font-size: var(--size-xl);
}

.value {
  margin-bottom: var(--space-l);

  font-size: var(--size-l);
}

.rating {
  margin-bottom: var(--space-n);

  color: var(--color-grafit);

  zoom: 1.6;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 420px;
}

.textarea {
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  margin-bottom: var(--space-xxl);

  text-align: left;
}

.checkbox {
  margin-bottom: var(--space-xxl);

  text-align: left;

  & > * {
    white-space: normal;
  }
}

.button {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.link {
  width: 100%;
  max-width: 420px;
  -webkit-tap-highlight-color: transparent;
}

.hide {
  display: none;
}

@media (--desktop-m) {
  .date {
    font-size: var(--size-l);
  }

  .value {
    font-size: var(--size-m);
  }
}

@media (--mobile-m) {
  .image {
    margin-bottom: var(--space-l);
  }

  .date {
    margin-bottom: var(--space-xxs);

    font-size: var(--size-m);
  }

  .value {
    margin-bottom: var(--space-n);

    font-size: var(--size-n);
  }

  .rating {
    margin-bottom: var(--space-s);
  }

  .textarea {
    height: 136px;
    margin-bottom: var(--space-l);
  }

  .form .button {
    height: 42px;
  }

  .checkbox {
    margin-bottom: var(--space-l);
  }
}

@media (max-width: 520px) {
  .form {
    max-width: 100%;
  }
}
