.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  line-height: 1.5;
  text-align: center;
}

.image {
  width: 100%;
  max-width: 200px;
  margin-bottom: var(--space-xxl);
}

.title {
  max-width: 600px;
  margin-bottom: var(--space-xs);

  font-size: var(--size-l);
}

.callCenter {
  max-width: 500px;

  font-weight: 500;

  font-size: var(--size-l);
}

.button {
  display: none;
  -webkit-tap-highlight-color: transparent;
}

@media (--desktop-m) {
  .title,
  .callCenter {
    max-width: 500px;

    font-size: var(--size-m);
  }
}

@media (--mobile-m) {
  .image {
    margin-bottom: var(--space-l);
  }

  .title {
    max-width: 400px;
    margin-bottom: var(--space-l);

    font-size: var(--size-n);
  }

  .callCenter {
    display: none;
  }

  .button {
    display: block;
    width: 100%;
    max-width: 330px;
  }
}

@media (max-width: 520px) {
  .button {
    max-width: 100%;
  }
}

@media (--mobile) {
  .title {
    max-width: 270px;
  }
}
