.container {
  position: relative;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 100%;

  background-color: #f5f3f1;

  &.leftSeparateText {
    & .text {
      grid-column: 1 / 2;
    }

    & :is(.asset, .playIcon) {
      grid-column: 2 / -1;
    }
  }

  &.rightSeparateText {
    & .text {
      grid-column: -2 / -1;
    }

    & :is(.asset, .playIcon) {
      grid-column: 1 / -2;
    }
  }
}

.asset {
  position: absolute;
  z-index: 1;

  grid-row: 1 / -1;
  grid-column: 1 / -1;

  inset: -3px;
}

.video {
  filter: brightness(0.7);

  transition: filter 200ms;
}

.playIcon {
  position: relative;
  z-index: 2;

  grid-row: 1 / -1;
  grid-column: 1 / -1;

  width: 84px;
  height: 84px;

  background-color: transparent;
  opacity: 1;

  transition: opacity 200ms;

  pointer-events: none;
  place-self: center;
}

.text {
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  box-sizing: border-box;
  padding: 40px;

  color: var(--color-black);
  line-height: 1.5;

  &.leftTop {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.leftCenter {
    align-items: flex-start;
    justify-content: center;
  }

  &.centerTop {
    align-items: center;
    justify-content: flex-start;
    margin-inline: auto;

    text-align: center;
  }

  &.centerCenter {
    align-items: center;
    justify-content: center;
    margin-inline: auto;

    text-align: center;
  }

  &.rightTop {
    align-items: flex-start;
    justify-content: flex-start;

    &.full {
      grid-column: -2 / -1;
    }
  }

  &.rightCenter {
    align-items: flex-start;
    justify-content: center;

    &.full {
      grid-column: -2 / -1;
    }
  }

  &.full {
    max-width: 573px;

    & .title {
      font-size: 44px;
    }

    & .subtitle {
      font-size: 25px;
    }

    & .link {
      font-size: 22px;
    }
  }

  &.half {
    max-width: 423px;

    & .title {
      font-size: 35px;
    }

    & .subtitle {
      font-size: 22px;
    }

    & .link {
      font-size: 22px;
    }
  }

  .link {
    &:after {
      background-color: var(--color-black);
    }

    &:before {
      background-color: var(--color-black);
    }
  }

  &.inversed {
    color: var(--color-white);

    .logo {
      filter: invert(1);
    }

    .link {
      &:after {
        background-color: var(--color-white);
      }

      &:before {
        background-color: var(--color-white);
      }
    }
  }
}

.logo {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 20px;

  font-weight: 500;
  font-family: var(--font-hero);
}

.subtitle {
  margin-bottom: 20px;
}

.link {
  position: relative;

  display: inline-flex;

  font-weight: 500;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 1px;

    opacity: 1;

    transition: opacity 100ms ease-in;

    content: '';
  }

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;

    display: block;
    width: 0;
    height: 1px;

    transition: width 250ms ease-in 100ms;

    content: '';
  }
}

.text:last-child {
  margin-bottom: 0;
}

@media (--desktop-m) {
  .text {
    padding: 25px;

    &.full {
      max-width: 496px;

      & .title {
        font-size: 30px;
      }

      & .subtitle {
        font-size: 18px;
      }

      & .link {
        font-size: 18px;
      }
    }

    &.half {
      & .title {
        font-size: 25px;
      }

      & .subtitle {
        font-size: 16px;
      }

      & .link {
        font-size: 18px;
      }
    }
  }

  .title {
    margin-bottom: 15px;
  }

  .subtitle {
    margin-bottom: 15px;
  }

  .playIcon {
    width: 60px;
    height: 60px;
  }
}

@media (--desktop) {
  .container {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }

  .asset {
    position: relative;

    grid-row: 1 / 2;
    grid-column: 1 / -1;

    height: 205px;
  }

  .image {
    height: 100%;
  }

  .logo {
    margin-bottom: 10px;
  }

  .text {
    grid-row: 2 / -1;
    grid-column: 1 / -1;
    padding: 15px 15px 25px;

    &.sameAlignment {
      align-items: flex-start;
      justify-content: flex-start;

      text-align: left;
    }

    &:is(.full, .half) {
      max-width: 100%;

      .title {
        font-size: 22px;
      }

      .subtitle {
        font-size: 14px;
      }

      .link {
        font-size: 16px;
      }
    }
  }

  .title {
    margin-bottom: 10px;
  }

  .subtitle {
    margin-bottom: 10px;
  }
}

@media (hover: hover) {
  .link:hover {
    &:before {
      width: 100%;
    }
    &:after {
      opacity: 0;
    }
  }

  .video:hover + .playIcon {
    opacity: 0;
  }

  .video:hover {
    filter: brightness(1);
  }
}
