.content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;

  opacity: 0;

  transition: opacity 0.2s;
}

.dropdown {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: calc(var(--z-index-fixed-panel) + 1);

  &.opened {
    .backdrop {
      cursor: url('./cross.svg'), pointer;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  opacity: 0;

  transition: opacity 0.4s;
}

.wrapper {
  background: white;
}

.container {
  padding-top: 168px;
}

.enterActive,
.enterDone {
  &.dropdown {
    .content {
      opacity: 1;
    }

    .backdrop {
      opacity: 1;
    }
  }
}

.exitActive,
.exitDone {
  &.dropdown {
    .content {
      opacity: 0;
    }

    .backdrop {
      opacity: 0;
    }
  }
}
