.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-top: var(--space-b);

  column-gap: var(--space-l);
  row-gap: var(--space-b);

  &:first-child {
    margin-top: 0;
  }
}
