.main {
  margin-bottom: var(--space-xxl);
}

.titleWraper {
  display: flex;
  justify-content: space-between;
}

.title {
  margin: 0;
  margin-bottom: var(--space-s);

  font-weight: 500;
  font-size: var(--size-n);
  line-height: 150%;
}

.clear {
  margin: auto 0 var(--space-s);

  color: var(--color-dark-gray);

  font-weight: 400;
  font-size: var(--size-s);
  line-height: 150%;

  border-bottom: 1px solid var(--color-dark-gray);

  &:hover {
    font-weight: 500;

    cursor: pointer;
  }
}

.requestTitle {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  margin: 0;

  margin-bottom: var(--space-xs);

  font-weight: 400;
  font-size: var(--size-s);
  line-height: 150%;

  word-break: break-word;
  overflow-wrap: break-word;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    font-weight: 500;

    cursor: pointer;
  }
}
