.topline {
  display: flex;
  align-items: center;

  padding: var(--space-xs) 0;

  color: var(--color-black);

  font-size: var(--size-s);
  line-height: 1.5;

  background: var(--color-light-gray);
  opacity: 1;

  transition: opacity 0.2s ease-in-out;

  &.hasInfoStripe {
    color: var(--color-white);

    background: var(--color-dirty);
  }
}

.container {
  composes: main from 'containers';
}

.content {
  display: flex;
  justify-content: space-between;
  min-height: 20px;
}

.close {
  position: absolute;
  top: var(--space-xs);
  right: var(--gap);

  display: block;
}

.infoStripe {
  position: absolute;
  right: 0;
  left: 0;

  display: flex;
  margin: auto;

  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.menuLink {
  z-index: 1;

  display: none;
}

@media (--only-desktop) {
  .topline {
    box-sizing: border-box;

    padding: 7px 0;
  }
  .container {
    align-items: center;
  }

  .menuLink {
    display: flex;
  }

  .close {
    display: none;
  }
}

@media (--desktop) {
  .content {
    display: flex;
    align-self: center;
    justify-content: center;
  }

  .infoStripe {
    position: static;
  }
}

@media (--mobile-m) {
  .container {
    column-gap: var(--space-s);
  }
  .content {
    max-width: 78%;
    margin: 0 auto;
  }
}

@media (--mobile-s) {
  .container {
    padding: 0;

    column-gap: var(--space-xxs);
  }
}
