.wrapper {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  min-width: 290px;
}

.itemWrapper {
  position: relative;

  width: 100%;
  height: 0;
  overflow: hidden;

  cursor: pointer;
}

.itemWrapper:nth-child(3n + 1) {
  grid-column: 1 / -1;
  padding-bottom: 38%;
}

.itemWrapper:not(:nth-child(3n + 1)) {
  padding-bottom: calc(62% + 10px);
}

/* Если в последнем ряду сетки не заполнена вторая колонка
— растянуть последний элемент на две колонки  */
.itemWrapper:nth-child(3n + 2):last-child {
  grid-column: 1 / -1;
  padding-bottom: 38%;
}

.item {
  position: absolute;
  inset: 0;
}

@media (--desktop) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .itemWrapper:nth-child(n) {
    grid-column: 1 / -1;
    height: auto;
    padding-bottom: 0;
  }

  .itemWrapper:nth-child(3n + 2):last-child {
    padding-bottom: 0%;
  }

  .item {
    position: static;
  }
}
