.copyright {
}

.toggle {
  display: inline-flex;
  align-items: center;

  cursor: pointer;
}

.text {
  color: var(--color-dark-gray);
  font-size: var(--size-s);
  line-height: 1.5;
}

.iconChevron {
  width: 10px;
  height: 10px;
  margin-left: var(--space-xxs);
}
