.wrapper {
  background-color: var(--color-light-gray);
}

.container {
  position: relative;

  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
  padding: 40px 70px;
}

.text {
  line-height: 1.5;
}

.links {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  align-items: flex-start;
  margin-bottom: var(--space-xxl);
}

.title {
  margin-bottom: var(--space-xs);

  font-weight: 500;
  font-size: var(--size-xl);
  font-family: var(--hero);
}

.description {
  max-width: 550px;
  margin-bottom: var(--space-xs);

  font-size: var(--size-n);
}

.link {
  display: inline-flex;

  font-size: var(--size-n);
}

.image {
  width: 100%;
  max-width: 270px;
  margin-top: auto;

  transform: translateY(var(--space-xxl));
}

@media (--desktop-m) {
  .container {
    padding: var(--space-xxl);
  }
}

@media (--mobile-m) {
  .container {
    flex-direction: column;
    padding: var(--space-xxl) var(--space-s) var(--space-n);
  }

  .image {
    margin-top: 0;
    margin-left: auto;

    transform: translateY(var(--space-n));
  }
}
