.paymentList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-s);
  align-items: center;
  margin: 0;
  padding: 0;

  list-style: none;
}

.icon {
  display: block;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  filter: grayscale(1);

  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: grayscale(0);
  }
}
