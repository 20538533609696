.timer {
  display: inline-block;

  font-weight: 500;
  font-size: var(--size-s);
  line-height: 1;
}

.rank {
  white-space: nowrap;
}
