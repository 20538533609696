.socialList {
  display: grid;

  align-items: center;
  margin: 0;
  padding: 0;

  list-style: none;
}

.socialList.sizeLarge {
  grid-template-columns: repeat(auto-fit, 60px);
  gap: var(--space-n);
  justify-content: center;
  .iconSocial {
    width: 60px;
    height: 60px;
  }
}

.socialList.sizeSmall {
  grid-template-columns: repeat(auto-fit, 30px);
  gap: var(--space-xs);
}

@media (--desktop) {
  .socialList.sizeLarge {
    grid-template-columns: repeat(auto-fit, 50px);

    .iconSocial {
      width: 50px;
      height: 50px;
    }
  }
}

@media (--mobile-m) {
  .socialList.sizeLarge {
    grid-template-columns: repeat(auto-fit, 40px);

    .iconSocial {
      width: 40px;
      height: 40px;
    }
  }
}

@media (--mobile) {
  .socialList.sizeLarge {
    grid-template-columns: repeat(3, 40px);
    gap: var(--space-n);
  }
}
