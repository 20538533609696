.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.loading {
  margin: auto auto 0;

  animation: rotate 2s infinite;
}

.circle {
  animation: animate 3s infinite;

  fill: none;
  stroke: var(--color-medium-gray);
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-dasharray: 160;
  stroke-dashoffset: 160;
}

.footer {
  margin-top: auto;
}

@keyframes animate {
  0% {
    stroke-dashoffset: 160;
  }
  50% {
    stroke-dashoffset: 10;
  }
  50.1% {
    stroke-dashoffset: 320;
  }
  100% {
    stroke-dashoffset: 160;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
