.banner {
  box-sizing: border-box;
  width: 100%;

  &.themeDark {
    color: var(--color-white);
  }

  &.themeLight {
    color: var(--color-black);
  }

  &.fullscreen {
    .image {
      aspect-ratio: '983:375';
    }

    .titleContainer {
      top: var(--space-xxl);
      right: 0;
      left: 0;
    }

    .link .title {
      font-size: var(--size-xb);
    }

    .description {
      font-size: var(--size-xl);
      text-align: center;
    }

    &.aligmentLeft {
      .titleContainer {
        top: 50%;

        align-items: flex-start;
        justify-content: center;
        box-sizing: border-box;
        width: 580px;
        padding: 0 var(--space-xxl);

        transform: translate3d(0, -50%, 0);
      }
    }
  }

  &.halfscreen {
    .image {
      aspect-ratio: '694:434';
    }

    .titleContainer {
      top: 0;
      bottom: 0;
      left: var(--space-xxl);

      align-items: flex-start;
      justify-content: center;
      width: 100%;

      max-width: 300px;
    }

    .link .title {
      font-size: var(--size-b);
      text-align: left;
    }

    .description {
      font-size: var(--size-l);
    }
  }
}

.link {
  position: relative;

  display: block;

  color: inherit;
}

.image {
  background-color: transparent;
}

.imageContainer {
  position: relative;
}

.discount {
  position: absolute;
  top: var(--space-l);
  right: var(--space-l);
  z-index: 2;

  color: var(--color-black);

  font-size: var(--size-l);
}

.titleContainer {
  position: absolute;

  display: flex;
  flex-direction: column;
  gap: var(--space-n);
  align-items: center;
}

.link .title {
  margin: 0;

  font-family: var(--font-hero);

  line-height: 1.5;
}

.description {
  margin: 0;

  font-size: var(--size-xl);
}

.moreLink {
  display: inline-block;

  font-size: var(--size-l);
  line-height: 1.5;
  vertical-align: baseline;
}

@media (--desktop-m) {
  .banner {
    box-sizing: border-box;
    width: 100%;

    &.fullscreen {
      .link .title {
        font-size: var(--size-xxl);
      }

      .description {
        font-size: var(--size-m);
      }

      .titleContainer {
        top: 25px;

        gap: var(--space-s);
      }

      &.aligmentLeft {
        .titleContainer {
          width: 365px;
          padding: 0 var(--space-m);
        }
      }
    }

    &.halfscreen {
      .link .title {
        font-size: var(--size-xl);
      }

      .description {
        font-size: var(--size-n);
      }

      .titleContainer {
        left: 25px;

        gap: var(--space-s);
        max-width: 213px;
      }
    }
  }

  .moreLink {
    font-size: var(--size-m);
  }

  .discount {
    top: var(--space-m);
    right: var(--space-m);

    font-size: var(--size-m);
  }
}

@media (--desktop) {
  .banner {
    box-sizing: border-box;
    width: 100%;

    &.fullscreen,
    &.halfscreen {
      .image {
        aspect-ratio: '330:344';
      }

      &.aligmentLeft .titleContainer,
      .titleContainer {
        top: auto;
        bottom: 0;

        gap: var(--space-xs);
        align-items: flex-start;
        width: 100%;
        padding: var(--space-s) var(--space-s) var(--space-m) var(--space-s);

        transform: unset;
      }

      .link {
        height: 344px;
      }

      .imageContainer {
        height: 100%;
      }

      .link .title {
        font-size: var(--size-l);
      }

      .description {
        font-size: var(--size-s);
      }

      .moreLink {
        font-size: var(--size-n);
      }
    }
  }

  .discount {
    top: var(--space-s);
    right: var(--space-s);
  }
}
