.item {
  &.promo .title {
    color: var(--color-red);

    &:hover {
      color: var(--color-dark-gray);
    }
  }

  &.fatty .title {
    font-weight: 500;
  }

  &.viewHybrid {
    .icon {
      display: flex;
    }
  }

  &.viewImage {
    .name {
      display: none;
    }

    .icon {
      display: flex;
    }
  }
}

.title {
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
  margin: 0;

  color: var(--color-black);
  font-size: var(--size-n);
  line-height: 1.5;

  white-space: nowrap;

  cursor: pointer;

  transition: color 150ms ease-in;

  &:hover {
    color: var(--color-dark-gray);
  }
}

.icon {
  align-items: center;
  justify-content: center;
  width: auto;
  height: 24px;

  background-color: transparent;
}

.rounded {
  padding: var(--space-xxs) var(--space-xs);

  border-radius: 20px;
}
