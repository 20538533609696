.controls {
  display: flex;
  flex-direction: column;
}

.increment {
  margin-bottom: var(--space-xs);
}

.decrement {
  margin-bottom: var(--space-n);
}
