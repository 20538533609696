.mainContainer {
  display: flex;
  gap: 7px;

  align-items: flex-end;
}

.blrContainer {
  align-items: center;
}

.descriptor {
  color: var(--color-black);
  font-weight: 500;
  font-size: 10px;
  line-height: 143%;
}

.logoContainer {
  position: relative;

  height: 35px;
  padding: 0 3.6px;
  &.logoBlrContainer {
    height: 40px;
  }
}

.opacityAnimate {
  animation: opacity 0.35s forwards ease-out;
}

.logo {
  position: relative;
  z-index: 2;
}

.background {
  --pink: #ffb4b3;
  --yellow: #fad576;
  --dirty: #d4c7a8;

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 31px;

  background: no-repeat
    linear-gradient(
      270deg,
      var(--yellow) 0%,
      var(--yellow) 25%,
      var(--pink) 25%,
      var(--pink) 50%,
      var(--dirty) 50%,
      var(--dirty) 75%,
      var(--yellow) 75%,
      var(--yellow) 100%
    );
  background-position: 100%;
  background-size: 400%;

  &.backgroundBlr {
    bottom: 5px;
  }
}

.animate {
  animation: change-bg 2.5s forwards ease-in-out;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes change-bg {
  30% {
    background-position: 100%;
  }
  45% {
    background-position: 66.6%;
  }
  65% {
    background-position: 66.6%;
  }
  80% {
    background-position: 33.3%;
  }
  85% {
    background-position: 33.3%;
  }
  100% {
    background-position: 0%;
  }
}

@media (--mobile-m) {
  .logoContainer {
    height: 30px;
    padding: 0 3px;
    &.logoBlrContainer {
      height: 30px;
    }
  }

  .background {
    height: 26.6px;
    &.backgroundBlr {
      bottom: 4px;

      height: 22.6px;
    }
  }
}
