.placeholder {
  flex-shrink: 0;
  height: 133px;
}

.bottomSheet {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-fixed-panel);

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 143px;

  background-color: var(--color-white);
  border-radius: var(--space-xs) var(--space-xs) 0 0;
  box-shadow: var(--shadow-popup);
}

.animated {
  transition: transform 200ms;
}

.handle {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  padding-bottom: var(--space-xxs);

  cursor: pointer;
}

.line {
  flex-shrink: 0;
  width: 35px;
  height: 5px;

  background-color: var(--color-medium-gray);
  border-radius: var(--space-xxs);
}

.body {
  width: 100%;
}

.draggableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.expanded .body {
  overflow-y: auto;
  scrollbar-width: none;
}

.body {
  &.unscrollable {
    overflow-y: hidden;
  }
}
