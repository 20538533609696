.header {
  composes: main from 'containers';

  position: relative;
  position: relative;

  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding-top: var(--space-xs);
  padding-bottom: var(--space-s);

  background: var(--color-white);
  border-bottom: 1px solid transparent;

  transition: border 0.4s;

  &.withShadow {
    border-color: var(--color-light-gray);
  }
}

.left {
  display: flex;
  align-items: center;

  column-gap: var(--space-s);
}

.iconSearch {
  cursor: pointer;
}

.left,
.userMenu {
  flex-basis: 75px;
  flex-grow: 0;
  flex-shrink: 0;
}

@media (--mobile) {
  .left {
    column-gap: var(--space-xs);
  }

  .left,
  .userMenu {
    flex-basis: 70px;
  }
}
