.delivery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--space-xxl) 0;

  background: var(--color-light-gray);

  border-bottom: 1px solid var(--color-dark-gray);
}

.inner {
  composes: main from 'containers';

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.title {
  margin: 0 0 var(--space-xs);

  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-xl);
  font-family: var(--font-hero);
  line-height: 37px;
}

.text {
  margin: 0 0 var(--space-xs);

  color: var(--color-black);
  font-size: var(--size-n);
  line-height: 150%;
}

.link {
  color: var(--color-black);
}

.more {
  display: inline-block;
}

.image {
  position: relative;

  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 269px;
  height: 103px;

  transform: translateY(var(--space-xxl));
}

@media print {
  .delivery {
    display: none;
  }
}

@media (--mobile) {
  .inner {
    display: block;
  }
}
