.popup {
  position: absolute;
  top: calc(100% + 7px);
  left: var(--space-n-neg);
  z-index: var(--z-index-popup);

  box-sizing: border-box;
  width: fit-content;

  padding: var(--space-s) var(--space-n);

  background: var(--color-white);
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
