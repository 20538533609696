.subtitle {
  margin: 0 0 var(--space-m);

  font-size: var(--size-b);
  line-height: 1.5;
  white-space: normal;
}

@media (--desktop-m) {
  .subtitle {
    margin: 0 0 var(--space-s);

    font-size: var(--size-xl);
  }
}

@media (--desktop) {
  .subtitle {
    font-size: var(--size-l);
  }
}

@media (--mobile-m) {
  .subtitle {
    margin: 0 0 var(--space-xs);
    margin-bottom: var(--space-s);

    font-size: var(--size-m);
  }
}
