.burger {
  width: 20px;
  min-width: 20px;
  height: 30px;
  padding: 0;

  background: transparent;
  background-image: url('icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px auto;
  border: 0;
  cursor: pointer;
}

@media (--mobile-m) {
  .burger {
    width: 30px;
  }
}
