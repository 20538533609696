.title {
  font-weight: 500;
  font-size: var(--size-n);
  line-height: 1.5;
}

.list {
  margin: var(--space-s) 0 0;
}

.item {
  &:hover {
    .subTitle {
      font-weight: 500;

      cursor: pointer;
    }
  }
}

.subTitle {
  display: block;
  padding: var(--space-xxs) 0;

  color: var(--color-black);
  font-size: var(--size-s);
  line-height: 1.5;
}
