.col {
  box-sizing: border-box;
  width: 100%;
}

.wrapperRegular {
  margin-bottom: var(--space-s);
}

.wrapperMedium {
  margin-bottom: var(--space-m);
}

.wrapperBig {
  margin-bottom: 45px;
}
