.header {
  padding-bottom: var(--space-s);
}

.title {
  display: block;
  margin: 0;

  color: var(--color-black);
  font-weight: 500;
  font-size: var(--size-s);
  line-height: 1.5;
}
