.footerLogo {
  display: inline-block;
}

.logoRus,
.logoUzb {
  width: 175px;
  height: 42px;
}

.logoKaz {
  width: 182px;
  height: 42px;
}

.image {
  width: 100%;
  height: 100%;
}
