.list {
  display: flex;
  align-items: center;
}

.icon {
  display: block;
}

.userMenuItem {
  position: relative;

  display: block;
  width: 30px;
  height: 30px;
  margin-right: var(--space-s);

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;

  transition: background-color 150ms ease-in;

  &:last-child {
    margin-right: 0;
  }
}

.popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;

  padding-top: var(--space-xs);
}

.count {
  position: absolute;

  top: 9px;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;

  color: var(--color-white);

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  transition: color 150ms ease-in;

  pointer-events: none;
}

.favorites .count {
  top: 8px;
}

@media (--mobile) {
  .userMenuItem {
    margin-right: var(--space-xs);
  }
}
