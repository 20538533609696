.block {
  margin-bottom: var(--space-s);
}

.offerDoc {
  padding-bottom: 2px;

  background-image: linear-gradient(var(--color-dark-gray), var(--color-dark-gray));
  background-repeat: no-repeat;
  background-position: left 0 bottom calc(16% - 3px);
  background-size: 100% 1px;

  &:hover {
    background-image: none;
  }
}
