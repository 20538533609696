.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-height: 100vh;
  margin-bottom: 60px;
}

.header,
.footer {
  padding-top: var(--space-e);
}

.wrapperIcon {
  box-shadow: inset 0 -1px 0px var(--color-dark-gray);
}

.iconFurniture {
  position: relative;

  width: 100%;
  height: 235px;

  background-image: url('./icons/furniture.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.contentWrapper {
  composes: main from 'containers';

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  margin: 0;
  padding-top: var(--space-xb);
  padding-bottom: var(--space-xs);

  font-weight: 500;
  font-size: var(--size-xb);
  font-family: var(--font-hero);
  line-height: 1.5;
}

.text {
  padding-bottom: var(--space-xxl);

  font-size: var(--size-m);
  line-height: 1.5;
}

.link {
  font-size: var(--size-n);
  line-height: 1.5;
}

@media (--desktop-m) {
  .heading {
    font-size: var(--size-b);
    line-height: 1.5;
  }

  .iconFurniture {
    height: 197px;

    background-size: contain;
  }
}

@media (--desktop) {
  .heading {
    font-size: var(--size-xxl);
    line-height: 1.5;
  }

  .iconFurniture {
    height: 154px;
  }

  .link {
    font-size: var(--size-s);
    line-height: 1.5;
  }
}

@media (--mobile-m) {
  .header,
  .footer {
    padding-top: var(--space-xb);
  }

  .wrapper {
    margin-bottom: 40px;
  }

  .heading {
    padding-top: var(--space-xxl);

    font-size: var(--size-xl);
    line-height: 1.5;
  }

  .link {
    font-size: var(--size-xs);
    line-height: 1.5;
  }

  .iconFurniture {
    height: 136px;
  }
}

@media (--mobile) {
  .iconFurniture {
    height: 101px;
  }

  .wrapper {
    margin-bottom: 20px;
  }

  .text {
    text-align: center;
  }
}
