.title {
  box-sizing: border-box;
  width: 100%;
  padding: var(--space-xxl) var(--space-s) var(--space-xl);

  font-weight: 500;
  font-size: var(--size-b);
  font-family: var(--hero);
  line-height: 1.5;
  text-align: center;

  background-color: var(--color-white);
}

@media (--desktop-m) {
  .title {
    padding: var(--space-xxl) var(--space-s) var(--space-l);

    font-size: var(--size-xxl);
  }
}

@media (--mobile-m) {
  .title {
    padding: var(--space-n) var(--space-s);

    font-size: var(--size-xl);
  }
}
