.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-modal);
}

.enterActive,
.enterDone {
  .content {
    transform: translate3d(0, 0, 0);
  }

  .overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}

.content {
  position: relative;
  z-index: 2;

  width: 100%;
  max-width: 360px;
  height: 100%;
  overflow-x: hidden;

  background: var(--color-white);
  transform: translate3d(-100%, 0, 0);

  transition: transform 0.4s;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  background: rgba(0, 0, 0, 0);

  transition: background-color 0.4s;
}
