.col {
  box-sizing: border-box;
  width: auto;
}

.wrapperMedium {
  margin-bottom: var(--space-m);
}

.wrapperBig {
  margin-bottom: 45px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 250px);
  justify-content: space-between;
  width: 100%;
}
