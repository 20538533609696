.placemark {
  position: relative;

  width: 15px;
  height: 15px;

  border-radius: 50%;
  box-shadow: 0 0 var(--space-n) 0 #00000033;
  transform: translate(-50%, -50%);
}
