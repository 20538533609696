.header {
  position: relative;
  z-index: 10;

  display: block;
  width: 100%;
  height: 136px;

  background: var(--color-white);

  transition: height 0.4s, margin 0.4s;

  &.fixed {
    height: 90px;

    .right {
      padding-top: 0;
    }

    .userMenu {
      height: auto;

      transform: none;
    }
  }

  &.hiddenSecondLevel {
    height: 90px;

    .secondLevel {
      transform: translate3d(0, -100%, 0);
    }
  }

  &.fixed.hiddenSecondLevel {
    box-shadow: 0 1px 0 0 var(--color-light-gray);
  }

  .regionQuestionPopup {
    top: -10px;
    right: auto;
    left: 0;

    transform: translateX(0);
  }
}

.firstLevel {
  position: relative;
  z-index: 6;

  padding-top: var(--space-n);
  padding-bottom: var(--space-m);

  background: var(--color-white);

  transition: padding 0.4s;
  &.withRoundedItem {
    padding-bottom: var(--space-n);
  }
}

.container {
  composes: main from 'containers';
}

.userMenu {
  height: 35px;

  margin-left: auto;

  transform: translate3d(0, 2px, 0);
}

.maxWidth {
  position: relative;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  margin-right: var(--space-s);
}

.wrapperLogotype {
  position: relative;

  margin-right: var(--space-xb);

  padding-bottom: 0;
}

.back {
  position: absolute;

  transform: translate3d(calc(-100% - 17px), 0, 0);
}

.search {
  flex: 0 0 auto;
  width: 100%;
  max-width: 713px;
  margin-left: var(--space-xxl);
}

.slider {
  display: flex;
  align-items: flex-end;
}

.headerIn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
}

.secondLevel {
  position: relative;
  z-index: 5;

  background-color: var(--color-white);

  transform: translate3d(0, 0, 0);

  transition: transform 0.4s;
}

.secondLevelContainer {
  position: relative;
  z-index: 6;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: var(--space-m);
  overflow-x: hidden;

  background: var(--color-white);
  .alignCenter {
    align-items: center;
    height: 31px;
  }
}

.logotypeDivanClub {
  height: 42px;
}

@media (--desktop-m) {
  .back {
    transform: translate3d(calc(-100% - 3px), 0, 0);
  }

  .wrapperLogotype {
    margin-right: var(--space-b);
    padding-bottom: 0;
  }

  .search {
    max-width: 418px;
  }

  .header {
    height: 133px;
  }
}
