.page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapperIcon {
  display: flex;
  justify-content: center;
  margin-top: var(--space-e);
}

.iconCode {
  z-index: 2;

  width: 100%;
  height: 196px;

  background-image: url('./icons/404.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  margin: 0;
  padding-top: var(--space-xb);
  padding-bottom: var(--space-xs);

  font-weight: 500;
  font-size: var(--size-xb);
  font-family: var(--font-hero);
  line-height: 1.5;
}

.text {
  padding-bottom: var(--space-xxl);

  font-size: var(--size-m);
  line-height: 1.5;
  text-align: center;
}

.link {
  margin-bottom: var(--space-mega);

  font-size: var(--size-n);
  line-height: 1.5;
}

@media (--desktop-m) {
  .iconCode {
    height: unset;
    padding-bottom: 15.587%;
  }

  .heading {
    font-size: var(--size-b);
    line-height: 1.5;
  }
}

@media (--desktop) {
  .iconCode {
    padding-bottom: 20.307%;
  }

  .heading {
    font-size: var(--size-xxl);
    line-height: 1.5;
  }

  .wrapperIcon {
    margin-top: var(--space-xb);
  }

  .link {
    margin-bottom: var(--space-xxe);

    font-size: var(--size-s);
    line-height: 1.5;
  }
}

@media (--mobile-m) {
  .iconCode {
    padding-bottom: 21.118%;
  }

  .heading {
    padding-top: var(--space-xxl);

    font-size: var(--size-xl);
    line-height: 1.5;
  }

  .link {
    margin-bottom: var(--space-e);

    font-size: var(--size-xs);
    line-height: 1.5;
  }

  .text {
    max-width: 486px;
  }
}

@media (--mobile) {
  .iconCode {
    padding-bottom: 34.27%;
  }

  .contentWrapper {
    padding: 0 var(--space-s);
  }

  .text {
    text-align: center;
  }
}
